import React from "react";
import "./Events.css";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import EventsTile from "../../../EventsTiles/EventTiles";
import Banner from "../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import { cdn } from "../../../../consts/cdn";

//Images
const BannerDesktop = cdn.images + "Events/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "Events/Banner-Mobile.jpg";

function Events() {
  return (
    <div className="events">
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />
      </Helmet>
      <Banner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="EVENTS"
        color="#365B7E"
      />
      <div className="content">
        <EventsTile
          date="September 2024"
          title="vNOTES: Taking MIGS to the Next Level "
          content="In this AAGL-sponsored webinar, Drs. Baekelandt, Kim-Ashchi and Lerner will share how they're achieving better patient outcomes with vNOTES. Watch a series of case presentations that demonstrate the versatility of vNOTES for patients with prior C-sections, large uteri and adnexal pathology. Engage in a live Q&A discussing how vNOTES is advancing the minimally invasive nature of MIGS."
          doctors="Drs. Jan Baekelandt, Sonnie Kim-Ashchi and Veronica Lerner"
          banner
          webinar
          pastevent
          eventurl="https://surgeryu.aagl.org/view?m=kKQcoa8BC"
        />
        <EventsTile
          date="May 2024"
          title="vNOTES Full Circle: From Physician to Patient"
          content="Join Drs. Elizabeth Baker, Elizabeth Boggs and Brandi Hardin as they present procedural footage and discuss why vNOTES is their preferred technique. With varied backgrounds, from traditional vaginal surgery to robotics, each surgeon will share how vNOTES increases access and visibility for complex procedures and improves patient outcomes. Dr. Boggs will also speak to her experience as a vNOTES patient herself and how her quick recovery allowed her to return to the OR within two weeks."
          doctors="Drs. Elizabeth Baker, Elizabeth Boggs and Brandi&nbsp;Hardin"
          webinar
          pastevent
          comingsoonurl
        />
        <EventsTile
          date="October 2023"
          title="vNOTES Webinar Series: Progressing Your Practice"
          content="Join us as Drs. Jean Dubuisson, Géraldine Giraudet and Grover May share how they have advanced their practices with vNOTES. Observe case presentations and engage in a live Q&A on how to approach complex cases, such as adnexal procedures and the removal of large uteri, with the vNOTES approach."
          doctors="Drs. Jean Dubuisson, Géraldine Giraudet and Grover May"
          webinar
          eventurl="https://vablet.com/s/wZQ7vko"
        />
        <EventsTile
          date="September 2023"
          title="Visibility and Accessibility: Why vNOTES Makes Sense in My Practice"
          content="In this AUGS-sponsored webinar, Drs. Cheryl Iglesia, Fiona Lindo, Thomas Pitman and Dani Zoorob will share why they choose the vNOTES approach for their urogynecologic procedures. Observe video case presentations to see first-hand how vNOTES improves vaginal access and visualization. Additionally, learn from the panel’s real-world experiences and patient outcomes when using this innovative approach."
          doctors="Drs. Cheryl Iglesia, Fiona Lindo, Thomas Pitman, and Dani Zoorob"
          webinar
          eventurl="https://www.youtube.com/watch?v=RfV-H-bgGIE"
        />
        <EventsTile
          date="May 2023"
          title="vNOTES Webinar Series: vNOTES Hysterectomy Foundations"
          content="Join us as Drs. Asha Bhalwal, Brandi Hardin, Jay Matkins and K. Nathan Parthasarathy share the fundamentals of vNOTES. Engage in a live Q&A with the panelists as they review a complete vNOTES hysterectomy, including patient selection, OR and GelPOINT® V-Path platform setup, colpotomy creation, postoperative care and patient outcomes."
          extra="Whether you’re new to vNOTES or curious to know more, attend this webinar to learn what is involved in a vNOTES hysterectomy and how this procedure can benefit you and your patients."
          doctors=" Drs. Asha Bhalwal, Brandi Hardin, Jay Matkins and K. Nathan Parthasarathy"
          webinar
          eventurl="https://vablet.com/s/wGYqkHh"
        />
        <EventsTile
          date="February 2023"
          title="Hysterectomies 101 with Dr. Byron Hapner"
          content="In this Hello Uterus podcast, Byron Hapner, DO, discusses the benefits of vNOTES. Listen now to learn more about how this minimally invasive surgical approach modernizes vaginal surgery for surgeons and patients."
          doctors="Dr. Byron Hapner"
          podcasturl="https://hello-uterus.simplecast.com/episodes/hysterectomies-101-with-dr-byron-hapner"
          podcast
        />
        <EventsTile
          date="August 2022"
          title="vNOTES Webinar Series: Applications for Urogynecology"
          content="Join us as Drs. Fiona Lindo, Grover May and Dani Zoorob present their high uterosacral ligament suspension cases and share insights on adopting vNOTES for urogynecologic procedures."
          doctors="Drs. Fiona Lindo, Grover May and Dani Zoorob"
          eventurl="https://vablet.com/s/mm4Kd3d"
          webinar
        />
        <EventsTile
          date="May 2022"
          title="Why vNOTES Makes Sense for My Patients"
          content="In this Contemporary OB/GYN podcast, Lamarr Tyler, DO, and his patient Christine discuss their unique perspectives on the benefits of vNOTES. Listen today to learn about the procedure, its growing adoption and the associated clinical outcomes from the point of view of both the patient and the surgeon."
          podcasturl="https://protect-us.mimecast.com/s/-MLJCmZ6MjfRyY60FGx9Tv?domain=contemporaryobgyn.net"
          podcast
        />
        <EventsTile
          date="May 2022"
          title="vNOTES Webinar Series: Improving Access and Visualization in High BMI Patients"
          content="Join us as Drs. Asha Bhalwal, Grover May and Alvaro Montealegre narrate vNOTES case presentations and engage in a live Q&A. Learn how vNOTES can broaden minimally invasive options for your high BMI patients through improved surgical access and visualization."
          doctors="Drs. Asha Bhalwal, Grover May and Alvaro Montealegre "
          eventurl="https://vablet.com/s/M1cSRuJ"
          webinar
        />

        <EventsTile
          date="February 2022"
          title="vNOTES Webinar Series: vNOTES Hysterectomy and BSO Video Case Presentations"
          content="You asked. We listened! Watch two video case presentations by Dr. Jan Baekelandt, with moderator Dr. Brandi Hardin. Interact live with our experts and learn how you can make vNOTES part of your practice."
          doctors="Drs. Jan Baekelandt and Brandi Hardin"
          eventurl="https://vablet.com/s/nPc6GIB"
          webinar
        />
        <EventsTile
          date="October 2021"
          title="Do More Than Before: How vNOTES Is Redefining GYN Surgery"
          content="Join Drs. Jan Baekelandt, Grover May, Erin Miller and Thomas Pitman as they share their diverse vNOTES experience through a series of procedural videos. Watch this expert panel and learn how vNOTES can help you do more than before vaginally for a variety of GYN cases."
          doctors="Drs. Jan Baekelandt, Grover May, Erin Miller and Thomas Pitman"
          eventurl="https://vablet.com/s/lq9qaHn"
          webinar
        />
        <EventsTile
          date="May 2021"
          title="Expand Your View of Vaginal Surgery with vNOTES"
          content="Watch Dr. Jan Baekelandt remove a 10cm cyst and perform a challenging hysterectomy with moderators Dr. Erica Stockwell and Dr. Grover May. Dr.&nbsp;Baekelandt, Dr.&nbsp;May, and Dr.&nbsp;Stockwell discuss the vNOTES technique and answer questions. Discover firsthand what surgeons and patients are saying about vNOTES."
          doctors="Drs. Jan Baekelandt, Erica Stockwell and Grover May"
          eventurl="https://vablet.com/s/ZfCK3Wb"
          webinar
        />
        <EventsTile
          date="February 2021"
          title="The Path to vNOTES"
          content="Whether you have taken a vNOTES course, signed up for one, or want to find out more, this is an opportunity to discover this compelling new gynecologic procedure."
          doctors="Drs. Amy Brown, Sandy Burnett, Sonnie Kim-Ashchi, Grover May, Erin Miller, Erica Stockwell, Lori Tucker and Despina Walsworth"
          eventurl="https://vablet.com/s/MEkGJza"
          webinar
        />
      </div>
    </div>
  );
}

export default Events;
